<template>
    <span class="fant-img" :class="{circular:isCircular,preview:isPreview}" :title="isPreview?'点击查看大图':''">
        <img :src="imgSrc" @error="error" v-bind="$attrs" :style="{width:width,height:height}"/> 
    </span>
</template>
<script>
export default {
	inheritAttrs:false,
    props:{
        width:{
            type:String,
            default:'100%'
        },
        height:{
            type:String,
            default:'100%'
        },
        isCircular:{
            type:Boolean,
            default:false
        },
        imgId:{
            type:String,
            default:''
        },
        prefix:{
            type:String,
            default:'/web/'
        },
        showDefault:{
            type:Boolean,
            default:true
        },
        type:{
            type:String,
            default:'firm'
        },
        isPreview:{
            type:Boolean,
            default:false
        },
    },
    computed:{  
        imgSrc(){
          console.log('照片组件>>>>', this.imgId)
            return this.imgId?this.imgPrefix+this.imgId:this.showDefault ? this.src : '';
        },
        imgPrefix(){
            return `${this.$BASE_URL}/web/open/file/`;
        }
    },
    data() {
        return {
            prefixWechat:'/',
            src:require('@/assets/images/defaultHeader.png'),
            firmSrc:`${this.$BASE_URL}/web/${this.type}/file/${this.imgId}?access_token=${sessionStorage.getItem("token")}`};
    },
    methods:{
        error(e){
            if(this.showDefault){
                // let flag=this.CheckImgExists(this.firmSrc);
                // e.target.src=flag ? this.firmSrc : this.src;
                e.target.src=this.src;
            }
			this.$emit('error')
        },
        CheckImgExists(imgurl) {
            var ImgObj = new Image(); //判断图片是否存在  
            ImgObj.src = imgurl;  
             //存在图片
            if (ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)) {  
                return true;
            } else {  
                return false;
            }   
        }
    }
};
</script>
<style scoped>
    .fant-img{
        box-sizing:border-box;
        display:inline-block;
        vertical-align: top;
		width:100%;
        height: 100%;
    }
	.fant-img img{
		border-radius: 0;
	}
    .circular img{
        border-radius: 50% !important;
        object-fit: cover;
    }
    .preview img{
        cursor: pointer;
    }
</style>