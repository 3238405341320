<style lang="scss">
  .red{
		background: red !important;
  }

</style>
<template>
  <div id="app" class="page-main" v-cloak style="height:100%;">
	<router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
  export default {
    name: 'App',
    provide () {
      return{
        reload: this.reload
      }
    },
    data () {
      return {
          tabEvent:{},
        isRouterAlive: true
      }
    },
    methods:{
      reload() {
        this.isRouterAlive = false
        this.$nextTick(function () {
          this.isRouterAlive = true
        })
      }
      },
    mounted(){
      window.$router = this.$router
      // window.onpopstate = () => {
      //     history.go(1)
      // }
    }
  }
</script>

