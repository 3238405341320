const $business = "/business";
const businessRouteMap = {
    'wisdom.ad': $business+"/wisdom/ad/ad",
	'bigdata.home': $business+"/wisdom/bigData/bigData",
	
	// 村居管理
	'rusticate.subsidy': $business+"/rusticate/subsidy/subsidy",
	'rusticate.logistics': $business+"/rusticate/logistics/logistics",
	'rusticate.reporting': $business+"/rusticate/reporting/reporting",
	'rusticate.jobwanted': $business+"/rusticate/jobwanted/jobwanted",

	'rusticate.rentinghouse': $business+"/rusticate/houserent/rentalManage/rentinghouse",
	'rusticate.contract': $business+"/rusticate/houserent/contractManage/contract",
	'rusticate.tenant': $business+"/rusticate/houserent/leaseholder/tenant",

	'rusticate.specialist': $business+"/rusticate/specialist/specialist",
	'train.manage': $business+"/rusticate/trainManage/trainManage",
	'advisory.manage': $business+"/rusticate/advisoryManage/advisoryManage",
	'home.info': $business+"/rusticate/homeInfo/homeInfo",
	'villager.info': $business+"/rusticate/villagerInfo/villagerInfo",
	'view.manege': $business+"/rusticate/viewManage/viewManage",
	'bus.manage': $business+"/rusticate/busManage/busManage",
	'foods.manage': $business+"/rusticate/foodsManage/foodsManage",
	'washroom.manage': $business+"/rusticate/washroomManage/washroomManage",
	'kindness.manage': $business+"/rusticate/kindnessManage/kindnessManage",
	// 村企管理
	'factory.info': $business+"/villageCompany/factoryInfo/factoryInfo",
	'company.info': $business+"/villageCompany/companyInfo/companyInfo",
	'employ.management': $business+"/villageCompany/employManage/employManage",

};
const routeMap = Object.assign({},businessRouteMap);
export default routeMap;