<style lang="scss" scoped>
	::v-deep .el-upload--picture-card{
		width: 84px;
    height: 84px;
		line-height: 86px;
	}
	::v-deep .el-upload-list li{
		width: 84px;
    height: 84px;
		line-height: 86px;
	}
	::v-deep .el-icon-upload-success{
		position: absolute;
		left: 37%;
		top: 37%;
		margin-top: 0!important;
	}
  ::v-deep .el-upload-list__item {
    transition: none !important;
  }
</style>
<style lang="scss">
  .hide .el-upload--picture-card{
    display: none;
	}
</style>
<template>
  <el-upload
    action=""
    :http-request="$fileUplaod"
    :data="{size:10,url:''}"  
    :with-credentials="true"
    :list-type="listType"
    :file-list="originFiles"
    :limit="limit"
    ref="uploadRef"
    :class="{'hide':uploadDisabled}"
    :on-exceed="exceed"
    :on-success="success"
    :on-change="handleFileChange"
    :on-remove="handleRemove"
    :on-error="error"
    :before-upload="beforeUpload"
    :multiple="multiple"
  >
    <i class="el-icon-plus"></i>
  </el-upload>
</template>

<script>
export default {
  props: {
    originFiles: {
      type: Array,
      default: () => {
        return [];
      }
    },
    listType: {
      type: String,
      default: "picture-card"
    },
    multiple: {
      type: Boolean,
      default: false
    },
    limit: Number
  },
  watch: {
      originFiles(fileList) {
        this.uploadDisabled = fileList.length >= this.limit;
      },
  },
  data() {
    return {
      uploadDisabled:false,
    };
  },
  methods: {
    getFileIds() {
      let fileIds = [];
      for (let item of this.$refs.uploadRef.uploadFiles) {
        if (item.id) {
          fileIds.push(item.id);
        } else if (item.response) {
          fileIds.push(item.response.data);
        }
      }
      return fileIds;
    },
    getFileArr() {
      let fileIds = [];
      for (let item of this.$refs.uploadRef.uploadFiles) {
        if (item.id) {
          fileIds.push({
            url:`${this.$BASE_URL}/web/open/file/${item.id}`,
            fileId:item.id
          });
        } else if (item.response) {
          fileIds.push({
            url:`${this.$BASE_URL}/web/open/file/${item.response.data}`,
            fileId:item.response.data
          });
        }
      }
      return fileIds;
    },
    beforeUpload(file) {
      const isImage = file.type === 'image/jpeg'||file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 10;

      if(!isImage){
        this.$message({message:"上传头像图片只能是jpg或png格式！",type:"error"});
      }

      if (!isLt5M) {
        this.$message({message:"上传图片大小不能超过10MB",type:"error"});
      }
      return isImage && isLt5M;
    },
    exceed() {
      this.$message({ type: "error", message: "超出文件上传数量" });
    },
    success() {
      this.$emit("success",this.$refs.uploadRef.uploadFiles);
    },
    error(err) {
      this.$message({ type: "error", message: "系统异常，请稍候重试" });
    },
    clear() {
      this.$refs.uploadRef.clearFiles();
    },
    // 移除
    handleRemove(file, fileList) {
        //手动触发change事件
      // console.log('asd',fileList)
      // console.log('uploadFiles',this.$refs.uploadRef.uploadFiles)
      this.$emit('deletePic',fileList)
      this.handleFileChange(file, fileList);
    },
    // 文件上传变化
    handleFileChange(file, fileList) {
      //是否展示图片+号
      this.uploadDisabled = fileList.length >= this.limit;
    },

  }
};
</script>
