const $base = "/base";
const businessRouteMap = {
    'citizen.manage': $base+"/baseManage/citizenManage/citizenManage",
    'items.manage': $base+"/baseManage/itemsManage/itemsManage",
    'check.info': $base+"/baseManage/checkInfo/checkInfo",
    'approval.process': $base+"/baseManage/approvalProcess/approvalProcess",
    'grid.manage': $base+"/baseManage/gridManage/gridManageMap",
    'village.todoList': $base+"/baseManage/villageTodoList/villageTodoList",
    'resolve.problem': $base+"/baseManage/resolveProblem/resolveProblem",
    //村务管理
    'culture.management': $base+"/villageAffairs/cultureManagement/cultureManagement",
    'news.management': $base+"/villageAffairs/newsManagement/newsManagement",
    'decision.management': $base+"/villageAffairs/decisionManagement/decisionManagement",
    'decide.management': $base+"/villageAffairs/decideManagement/decideManagement",
    'politics.management': $base+"/villageAffairs/politicsManagement/politicsManagement",
    'construction.management': $base+"/villageAffairs/constructionManagement/constructionManagement",
    //村企管理
    'business.opportunity': $base+"/villageCompany/businessOpportunity/businessOpportunity",
    'contract.management': $base+"/villageCompany/factoryRental/contractManagement/contractManagement",
    'factory.management': $base+"/villageCompany/factoryRental/factoryRent/factoryRent",

    //政务管理
    'reservation.overview': $base+"/affairsManagement/reservationManagement/reservationOverview/reservationOverview",
    'vaccine.reservation': $base+"/baseManage/vaccineReservation/vaccineReservation",
    'clean.manage': $base+"/baseManage/cleanManage/cleanManage",
    'penalty.notice': $base+"/baseManage/cleanManage/penaltyNotice",
    'inspection.items': $base+"/baseManage/cleanManage/inspectionItems",
    'overTime.record': $base+"/baseManage/cleanManage/overTimeRecord",
    'blacklist': $base+"/baseManage/cleanManage/blacklist",
    'clean.group': $base+"/baseManage/cleanManage/cleanGroup",

    'check.plan': $base+"/affairsManagement/patrolManagement/checkPlan/makePlan",
    'check.map': $base+"/affairsManagement/patrolManagement/checkMap/checkMap",
    'check.record': $base+"/affairsManagement/patrolManagement/checkRecord/checkRecord",

    'thing.management': $base+"/affairsManagement/thingManagement/thingManagement",
    //village.todoList

};
const routeMap = Object.assign({},businessRouteMap);
export default routeMap;